import { Toaster } from 'react-hot-toast';
import RoutePath from './route';
import './services/i18n';
import './App.scss'


function App() {
  return (
    <div className="App">
      <Toaster />
      <RoutePath />
    </div>
  );
}

export default App;
