import { Button, Checkbox, TextField, useMediaQuery, useTheme } from "@mui/material";
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import toast from 'react-hot-toast';
import "./ContactForm.scss"
import { useState } from "react";
import emailjs from '@emailjs/browser'
import EnvS from "../services/EnvS";


interface Props {
  className?: string;
  children: React.ReactNode;
}

const ContactForm: React.FC<Props> = ({ className, children }) => {
  const { t } = useTranslation(["translation", "de"])
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    dataProtectionTermsAccept: false,
  })

  const handleFormSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();


    console.log("serviceId", EnvS.getServiceId(), "templateId", EnvS.getTemplateId(), "publicKey", EnvS.getPublicKey());

    emailjs.send(EnvS.getServiceId(), EnvS.getTemplateId(), {
      from_name: `${formData.firstName} ${formData.lastName} with email ${formData.email} and phone number ${formData.phoneNumber} and newsletter: ${formData.dataProtectionTermsAccept}`,
      to_name: 'Hizelus Engineering',
      from_email: formData.email,
      message: formData.message,
    }, {
      publicKey: EnvS.getPublicKey(),
    }).then(
      (response: { status: any; text: any }) => {
        toast.success('Message sent successfully');
        console.log("SUCCESS!", response.status, response.text);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          message: "",
          dataProtectionTermsAccept: false,
        });
      },
      (error: any) => {
        toast.error('Failed to update');
        console.log("FAILED...", error);
      }
    );

  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value, // Handle checkbox properly
    });
  };
  return (
    <div className={className}>
      <span className="contact-form-main-title">{t("contactForm.title")}</span>
      <form onSubmit={handleFormSubmit}>
        <Row>
          <span className="contact-form-field-title">
            {t("contactForm.title1")}
            <hr style={{ textAlign: "left", width: "10%" }} />
          </span>
          <Col className="col-12 col-md-6">
            <Row className="contact-form-field">
              <Col>
                <TextField
                  required
                  id="outlined-required"
                  label="First Name"
                  name="firstName"
                  type="text"
                  value={formData.firstName}
                  onChange={handleFormChange}
                />
              </Col>
              <Col>
                <TextField
                  required
                  id="outlined-required"
                  label="Last Name"
                  name="lastName"
                  type="text"
                  value={formData.lastName}
                  onChange={handleFormChange}
                />
              </Col>
            </Row>
            <Row className="contact-form-field">
              <Col>
                <TextField
                  required
                  id="outlined-required"
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleFormChange}
                />
              </Col>
              <Col>
                <TextField
                  required
                  id="outlined-required"
                  label="Phone Number"
                  name="phoneNumber"
                  type="tel"
                  value={formData.phoneNumber}
                  onChange={handleFormChange}
                />
              </Col>
            </Row>
            <Row className="contact-form-field-message-container">
              <Col>
                <TextField
                  id="outlined-multiline-static"
                  label="Your Message"
                  name="message"
                  fullWidth
                  multiline
                  rows={6}
                  value={formData.message}
                  onChange={handleFormChange}
                />
              </Col>
              <Col className="text-left">
                <Checkbox
                  checked={formData.dataProtectionTermsAccept}
                  name="dataProtectionTermsAccept"
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={handleFormChange}
                />
                <span className="contact-form-field-message-container-text">
                  {t("contactForm.subtitle")}
                </span>
              </Col>
              <Col>
                <Button fullWidth={isMobile} sx={{ backgroundColor: '#3d43d2' }} variant="contained" type="submit">
                  {t("contactForm.button")}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>{children}</Col>
        </Row>
      </form>
    </div>
  )
}

export default ContactForm