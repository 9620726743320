/* eslint-disable import/no-anonymous-default-export */
export default {
  translation: {
    global: {
      title: 'Beste IT-Lösung für Ihr Unternehmen',
      subtitle:
        'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
      button: 'Vereinbaren Sie eine kostenlose Beratung',
    },
    about: {
      title: 'Über Uns',
      subtitle:
        'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
    },
    services: {
      title: 'Dienstleistungen',
      subtitle:
        'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
    },
    contact: {
      title: 'Kontaktieren Sie uns',
      subtitle:
        'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
    },
    faqs: {
      title: 'FAQs',
      subtitle:
        'Haben Sie Fragen zu unseren Dienstleistungen? Entdecken Sie unten unsere häufig gestellten Fragen, um Antworten auf häufige Anfragen zu finden. Wenn Ihre Frage nicht aufgeführt ist, zögern Sie nicht, uns direkt zu kontaktieren. Unser erfahrenes Team steht Ihnen zur Verfügung, um Ihnen die Informationen zu geben, die Sie benötigen.',
      question1:
        'Wie lange dauert die Entwicklung einer maßgeschneiderten Softwarelösung?',
      answer1:
        'Der Entwicklungszeitplan für eine maßgeschneiderte Softwarelösung kann je nach Komplexität und Umfang des Projekts variieren. Während unseres ersten Beratungsgesprächs bewerten wir Ihre Anforderungen und geben eine geschätzte Zeitlinie basierend auf Faktoren wie Funktionalität, Integrationen und Tests an. Unser Team arbeitet effizient, um qualitativ hochwertige Lösungen innerhalb der vereinbarten Zeitrahmen zu liefern.',
      question2:
        'Können Sie nach Abschluss des Projekts weiterhin Support und Wartung anbieten?',
      answer2:
        'Absolut! Wir bieten laufende Support- und Wartungsdienste an, um den reibungslosen Betrieb Ihrer Softwarelösung zu gewährleisten. Unser Team stellt rechtzeitige Updates, Fehlerbehebungen und Sicherheits-Patches bereit, um Ihre Software auf dem neuesten Stand und optimiert zu halten. Wir bieten verschiedene Support-Pakete an, die auf Ihre spezifischen Bedürfnisse zugeschnitten sind, um Ihnen Sicherheit und fortlaufende Unterstützung nach dem Start zu gewährleisten.',
      question3:
        'Wie gewährleisten Sie die Sicherheit und Vertraulichkeit meiner Daten?',
      answer3:
        'Wir priorisieren die Sicherheit und Vertraulichkeit Ihrer Daten. Wir folgen den besten Praktiken der Branche und implementieren robuste Sicherheitsmaßnahmen, um Ihre Informationen vor unbefugtem Zugriff, Verstößen und Datenverlust zu schützen. Wir können spezifische Sicherheitsanforderungen während unserer ersten Diskussionen besprechen und unsere Lösungen an Ihre Datenschutzbedürfnisse anpassen.',
      question4:
        'Können Sie die Softwarelösung mit bestehenden Systemen oder Drittanbieteranwendungen integrieren?',
      answer4:
        'Ja, wir sind auf nahtlose Integrationen spezialisiert. Unser Team hat umfangreiche Erfahrung in der Integration von Softwarelösungen mit verschiedenen Systemen und Drittanbieteranwendungen. Ob es sich um die Verbindung mit einem bestehenden CRM, Zahlungsgateway oder anderer wesentlicher Software handelt, wir gewährleisten einen reibungslosen Datenfluss und Interoperabilität, um den Wert Ihrer Softwarelösung zu maximieren.',
      question5:
        'Wie stellen Sie sicher, dass die Softwarelösung benutzerfreundlich ist?',
      answer5:
        'Benutzererfahrung hat für uns oberste Priorität. Unser Design- und Entwicklungsprozess integriert benutzerzentrierte Prinzipien wie Benutzerforschung und Usability-Tests. Wir streben danach, intuitive und benutzerfreundliche Schnittstellen zu schaffen, die ein nahtloses und angenehmes Erlebnis für Ihre Endbenutzer bieten. Wir schätzen auch Ihr Feedback während des Entwicklungsprozesses, um die Benutzererfahrung zu verfeinern und die Zufriedenheit sicherzustellen.',
      question6: 'Was ist Ihr Ansatz für Projektmanagement?',
      answer6:
        'Wir folgen einem umfassenden Projektmanagementansatz, der Transparenz, effektive Kommunikation und termingerechte Lieferung gewährleistet. Unser Team nutzt branchenübliche Methoden wie Agile oder Wasserfall, je nach Art des Projekts. Wir bieten regelmäßige Fortschrittsupdates, fördern Kundenfeedback und priorisieren Ihre Zufriedenheit während des gesamten Projektlebenszyklus.',
      question7: 'Wie ist Ihre Preisstruktur?',
      answer7:
        'Wir bieten wettbewerbsfähige Preise basierend auf den spezifischen Anforderungen jedes Projekts. Da jedes Projekt einzigartig ist, bewerten wir Faktoren wie Komplexität, Umfang und Zeitrahmen, um ein maßgeschneidertes Angebot zu erstellen. Seien Sie versichert, unsere Preisgestaltung ist transparent und wir streben danach, Ihnen einen Mehrwert und ROI für Ihre Investition in unsere Dienstleistungen zu bieten.',
    },
    footer: {
      title:
        'Digitale Erfolgsgeschichten von der Idee bis zur Realität - wir verwandeln brillante Ideen in großartige Produkte, die das Geschäft unserer Kunden im großen Maßstab wachsen lassen',
      userfulLinks: {
        title: 'Nützliche Links',
        home: 'Startseite',
        about: 'Über Uns',
        services: 'Unsere Dienstleistungen',
        contact: 'Kontaktieren Sie uns',
        faqs: 'FAQs',
      },
      contact: {
        title: 'Kontaktieren Sie uns',
        address: 'Adresse: Eidelstedter Dorfstr. 3, 22527 Hamburg Deutschland',
        phone: 'Telefon: +4917642056661',
        email: 'contact@hizelus.com',
      },
      social: {
        title: 'Soziale Medien',
        facebook: 'Facebook',
        twitter: 'Twitter',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
      },
    },
    header: {
      home: 'Startseite',
      about: 'Über Uns',
      services: 'Unsere Dienstleistungen',
      contact: 'Kontaktieren Sie uns',
      faqs: 'FAQs',
      jobs: 'Schulungen',
    },
    coreServices: {
      title: 'Kern Dienstleistungen',
      subtitle:
        'Willkommen bei Hizelus Engineering - Ihr führender Software-Beratungsdienstleister, der Spitzentechnologielösungen in den Bereichen Mobile Development, Web Development, Design, Branding und Datenmanagement liefert. Bei Hizelus Engineering sind wir leidenschaftlich daran interessiert, innovative und maßgeschneiderte Softwarelösungen bereitzustellen, um Unternehmen in der digitalen Ära zum Erfolg zu verhelfen. Mit unserer Expertise in den Bereichen Mobile Development, Web Development, Design, Branding und Datenmanagement befähigen wir Organisationen, ihre Ziele zu erreichen und der Konkurrenz einen Schritt voraus zu sein.',
      service1: {
        title: 'Design',
        description:
          'Ideen in beeindruckende Realitäten verwandeln. Unser talentiertes Team erstellt visuell ansprechende Designs, die das Wesen Ihrer Marke einfangen, bei Ihrem Publikum Anklang finden und Ihre Markenidentität stärken. Von Logos bis hin zu Websites schaffen wir eindrucksvolle Designs, die einen bleibenden Eindruck hinterlassen.',
      },
      service2: {
        title: 'Mobile Development',
        description:
          'Unser erfahrenes Mobile Development Team erstellt intuitive und funktionsreiche Anwendungen für iOS- und Android-Plattformen. Durch die Nutzung neuester Technologien und bewährter Praktiken der Branche entwickeln wir mobile Lösungen, die nahtlose Benutzererfahrungen bieten und Engagement fördern.',
      },
      service3: {
        title: 'Web Development',
        description:
          'Von responsiven Websites bis hin zu komplexen Webanwendungen decken unsere Webentwicklungsdienste unterschiedliche Geschäftsanforderungen ab. Wir kombinieren modernste Technologien, kreatives Design und robuste Funktionalität, um Websites zu liefern, die das Publikum fesseln und greifbare Ergebnisse liefern.',
      },
      service4: {
        title: 'Branding',
        description:
          'Ihre Marke ist das Herz und die Seele Ihres Unternehmens, und wir verstehen ihre Bedeutung. Unsere talentierten Designer arbeiten eng mit Ihnen zusammen, um visuell atemberaubende Designs zu erstellen, die mit Ihrer Markenidentität übereinstimmen. Wir schaffen beeindruckende Benutzeroberflächen, Logos und Marketingmaterialien, die bei Ihrem Zielpublikum einen bleibenden Eindruck hinterlassen.',
      },
      service5: {
        title: 'Data Engineering',
        description:
          'Daten sind das Rückgrat moderner Unternehmen, und eine effiziente Datenverwaltung ist entscheidend für den Erfolg. Unsere Datenmanagementlösungen sorgen dafür, dass Ihre wertvollen Informationen organisiert, gesichert und leicht zugänglich sind. Von der Datenintegration und -speicherung bis hin zu Analysen und Berichterstattung optimieren wir Ihre Datenprozesse, um fundierte Entscheidungen zu ermöglichen.',
      },
      more_services: {
        text: 'Mehr über unsere Dienstleistungen',
      },
    },
    feedBack: {
      title: 'Was unsere Kunden sagen',
      step1: {
        name: 'Philipp Schneiderwind',
        position: 'CEO, Firma',
        feedback:
          '"Die Zusammenarbeit mit Hizelus Engineering war ein Wendepunkt für unser Geschäft. Ihre Expertise in der Webentwicklung half uns, eine visuell beeindruckende und benutzerfreundliche Website zu erstellen, die unsere Online-Präsenz erheblich verbessert hat. Die Professionalität, Detailgenauigkeit und pünktliche Lieferung ihres Teams haben unsere Erwartungen übertroffen. Wir empfehlen ihre Dienstleistungen wärmstens."',
      },
      step2: {
        name: 'Ogbonna Chinedu Emmanuel',
        position: 'CEO, Firma',
        feedback:
          '"Ich kann Hizelus Engineering nicht genug für ihre herausragenden Dienstleistungen in der mobilen App-Entwicklung danken. Sie haben sich die Zeit genommen, unsere Anforderungen zu verstehen und eine hochfunktionale und intuitive App geliefert, die die Art und Weise, wie unsere Kunden mit unserem Unternehmen interagieren, transformiert hat. Das Niveau der Professionalität und technischen Expertise, das ihr Team zeigte, war beeindruckend. Ich würde nicht zögern, wieder mit ihnen zusammenzuarbeiten."',
      },
      step3: {
        name: 'UL.RIFATA BURDZEVICA',
        position: 'CEO, Firma',
        feedback:
          '"Die Entscheidung, Hizelus Engineering für unsere Software-Beratungsbedürfnisse zu wählen, war eine der besten Entscheidungen, die wir getroffen haben. Ihre Berater haben uns wertvolle Einblicke und Anleitungen gegeben, die uns halfen, unsere Prozesse zu optimieren und Wachstumschancen zu identifizieren. Ihre Expertise und ihr Engagement für unseren Erfolg waren während des gesamten Projekts offensichtlich. Wir sind mit den Ergebnissen äußerst zufrieden und freuen uns auf zukünftige Zusammenarbeit."',
      },
    },
    ourWork: {
      title: 'Unsere Arbeit',
      subtitle:
        'Bei Hizelus Engineering sind wir stolz auf unsere Fähigkeit, außergewöhnliche Softwarelösungen für unsere Kunden zu liefern. Mit Expertise in den Bereichen Mobile Development, Web Development, Design, Branding und Datenmanagement schaffen wir innovative und maßgeschneiderte Lösungen, die auf die einzigartigen Bedürfnisse Ihres Unternehmens zugeschnitten sind. Unser engagiertes Team von Fachleuten arbeitet eng mit Ihnen zusammen, um Ihre Ziele und Herausforderungen zu verstehen und sicherzustellen, dass unsere Arbeit Ihre Erwartungen übertrifft und greifbare Ergebnisse für Ihr Geschäft liefert.',
      work1: {
        title: 'Projekt 1',
        subtitle:
          'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
      },
      work2: {
        title: 'Projekt 2',
        subtitle:
          'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
      },
      work3: {
        title: 'Projekt 3',
        subtitle:
          'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
      },
      work4: {
        title: 'Projekt 4',
        subtitle:
          'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
      },
      work5: {
        title: 'Projekt 5',
        subtitle:
          'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
      },
      work6: {
        title: 'Projekt 6',
        subtitle:
          'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
      },
    },
    booking: {
      title: 'Stärken Sie Ihr Geschäft mit Softwarelösungen',
      subtitle:
        'Bereit, das Potenzial von Softwarelösungen für Ihr Unternehmen zu erschließen? Vereinbaren Sie einen Beratungstermin mit Hizelus Engineering, um Ihre spezifischen Bedürfnisse, Herausforderungen und Ziele zu besprechen. Unser Expertenteam wird maßgeschneiderte Empfehlungen und Einblicke bieten, um Ihnen zu helfen, fundierte Entscheidungen zu treffen und Ihr Geschäft voranzutreiben. Verpassen Sie nicht die Gelegenheit, Ihr Geschäft zu revolutionieren – vereinbaren Sie noch heute Ihren Beratungstermin.',
      button: 'Vereinbaren Sie eine kostenlose Beratung',
    },
    coreValue: {
      title: 'Unsere Kernwerte',
      subtitle:
        'Bei Hizelus Engineering bilden unsere Kernwerte Integrität, Innovation und Exzellenz das Fundament unserer Unternehmenskultur und treiben unser Engagement an, außergewöhnliche Softwarelösungen zu liefern. Diese Werte prägen die Art und Weise, wie wir Geschäfte tätigen, mit Kunden zusammenarbeiten und jedes Projekt angehen, um sicherzustellen, dass wir stets qualitativ hochwertige Ergebnisse liefern.',
      value1: {
        title: 'Integrität',
        subtitle:
          'Integrität steht im Mittelpunkt unseres Handelns. Wir priorisieren Ehrlichkeit, Transparenz und ethisches Verhalten in unseren Interaktionen mit Kunden, Partnern und Teammitgliedern. Vertrauen ist die Grundlage erfolgreicher Beziehungen, und wir bemühen uns, das Vertrauen unserer Stakeholder durch unser unerschütterliches Engagement für Integrität zu gewinnen und zu erhalten.',
      },
      value2: {
        title: 'Innovation',
        subtitle:
          'Wir sehen Innovation als treibende Kraft hinter unserem Erfolg. Wir fördern eine Kultur der Neugier, Exploration und des Vorwärtsdenkens, indem wir unser Team ermutigen, Grenzen zu überschreiten und kreative Lösungen für komplexe Herausforderungen zu finden. Indem wir an der Spitze neuer Technologien und Branchentrends bleiben, ermöglichen wir unseren Kunden innovative Softwarelösungen, die Wachstum und Wettbewerbsvorteile bieten.',
      },
      value3: {
        title: 'Teamwork',
        subtitle:
          'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
      },
      value4: {
        title: 'Exzellenz',
        subtitle:
          'Exzellenz ist unser Standard. Wir sind bestrebt, in allen Aspekten unserer Arbeit außergewöhnliche Ergebnisse zu liefern. Von der Qualität unseres Codes bis zur Benutzererfahrung unserer Anwendungen, wir überlassen nichts dem Zufall in unserem Streben nach Exzellenz. Wir bemühen uns kontinuierlich, Erwartungen zu übertreffen, Fristen einzuhalten und unseren Kunden einen unvergleichlichen Service zu bieten.',
      },
      value5: {
        title: 'Kundenfokus',
        subtitle:
          'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
      },
    },
    whoWeAre: {
      title:
        'Wer wir sind - Ihr vertrauenswürdiger Software-Beratungsdienstleister',
      subtitle:
        'Wir sind ein Team erfahrener Software-Fachleute, die sich der Lieferung außergewöhnlicher Ergebnisse verpflichtet fühlen. Mit tiefem Branchenwissen und einem kundenorientierten Ansatz bieten wir maßgeschneiderte Softwarelösungen, die Wachstum und Effizienz fördern. Als Ihr vertrauenswürdiger Partner hören wir auf Ihre Bedürfnisse, nutzen fortschrittliche Technologien und liefern pünktlich und im Budgetrahmen. Lassen Sie uns Ihr zuverlässiger Software-Beratungsdienstleister sein – kontaktieren Sie uns noch heute, um die Kraft der Technologie für Ihr Geschäft zu erschließen.',
    },
    whyWeDoIt: {
      title: 'Warum wir es tun - Unternehmen durch Softwarelösungen stärken',
      subtitle:
        'Wir werden von einem klaren Ziel angetrieben: Unternehmen durch innovative Softwarelösungen zu stärken. Mit einem Fokus auf Exzellenz und einer Leidenschaft für positive Auswirkungen nutzen wir fortschrittliche Technologien, um Abläufe zu optimieren, Effizienz zu steigern und nachhaltiges Wachstum zu fördern. Als Ihr vertrauenswürdiger Partner sind wir bestrebt, Ihrem Geschäft zum Erfolg in der digitalen Landschaft zu verhelfen.',
    },
    team: {
      title: 'Unser Team - Experten, die sich Ihrem Erfolg widmen',
      subtitle:
        'Unser Team aus erfahrenen Fachleuten ist leidenschaftlich daran interessiert, außergewöhnliche Softwarelösungen zu liefern. Mit vielfältiger Expertise und einem Engagement für Exzellenz arbeiten wir eng mit Ihnen zusammen, um Ihre Ideen in die Realität umzusetzen. Von maßgeschneiderten mobilen Anwendungen bis hin zu beeindruckenden Websites ist unser Team hier, um Ihre Vision zum Leben zu erwecken und Ihren Erfolg zu gewährleisten.',
      member1: {
        name: 'Hilary Ogalagu',
        position: 'CEO, Software Engineer, Hizelus',
      },
      member2: {
        name: 'Narender Vaddepelly',
        position: 'CTO - Senior Software Developer, Hizelus',
      },
      member3: {
        name: 'Kenneth Obi',
        position: 'FullStack Developer, Hizelus',
      },
      member4: {
        name: 'Vanessa Laturnus',
        position: 'Content Manager, Hizelus',
      },
      member5: {
        name: 'John Doe',
        position: 'CEO, Firma',
      },
      member6: {
        name: 'John Doe',
        position: 'CEO, Firma',
      },
    },
    stayConnected: {
      title: 'Bleiben Sie mit der Community verbunden',
      subtitle:
        'Werden Sie Teil unserer lebendigen Community bei Hizelus und entdecken Sie endlose Möglichkeiten für Engagement, Lernen und Wachstum. Vernetzen Sie sich mit Gleichgesinnten, greifen Sie auf wertvolle Ressourcen zu und bleiben Sie über die neuesten Trends und Erkenntnisse informiert. Lassen Sie uns gemeinsam in der Welt der Technologie erfolgreich sein.',
      button: 'Abonnieren',
    },
    OurServices: {
      title: 'OUR SERVICES',
      title1: 'Design',
      subtitle1:
        'We understand the power of design to leave a lasting impression. Our talented designers work closely with you to create visually stunning designs that capture the essence of your brand and resonate with your audience. From captivating logos and engaging marketing materials to intuitive user interfaces, our design solutions make your brand stand out and leave a lasting impact.',
      title2: 'Mobile Development',
      subtitle2:
        'In today`s mobile-centric world, having a strong presence on smartphones is crucial. Our expert mobile development team specializes in creating innovative and user-friendly applications for iOS and Android platforms. By leveraging the latest technologies, we craft mobile solutions that enhance user experiences, drive engagement, and position your business for success in the mobile landscape.',
      title3: 'Web Development',
      subtitle3:
        'Your website is often the first point of contact for potential customers, making it essential to have an impressive online presence. At Hizelus Engineering, we offer web development services that go beyond aesthetics. Our team combines creative design with technical expertise to deliver responsive websites and dynamic web applications. With a focus on user experience and seamless functionality, we ensure your online presence delivers results.',
      title4: 'Branding',
      subtitle4:
        'A strong brand identity is the cornerstone of business success. Our branding experts work closely with you to develop a comprehensive branding strategy that resonates with your target audience. From logo design and visual identity to defining your brand’s messaging framework, we help you create a compelling brand that stands out and fosters lasting connections with your customers.',
      title5: 'Digital Solutions',
      subtitle5:
        'In a fast-paced digital landscape, leveraging technology for business growth is essential. Our digital solutions are tailored to your unique needs, integrating technology to streamline processes, enhance customer experiences, and improve business performance. From e-commerce platforms and content management systems to automation and digital marketing solutions, we help you capitalize on digital opportunities and stay ahead of the competition.',
      title6: 'Consulting',
      subtitle6:
        'Navigating the complexities of software projects can be challenging. Our experienced consultants provide valuable guidance and strategic advice to help you make informed decisions. Whether you need support with project planning, technology selection, or process optimization, we offer expert insights and industry best practices to ensure the success of your software projects.',
      title7: 'Data Management',
      subtitle7:
        'Data is the lifeblood of modern businesses, and effective data management is crucial. Our data management solutions encompass data integration, storage, analytics, and reporting. We help you harness the power of your data by organizing, securing, and analyzing it, enabling you to make data-driven decisions and gain a competitive edge in your industry.',
      title8: 'Artificial Intelligence (AI) Solutions',
      subtitle8:
        'Harness the power of artificial intelligence to transform your business. Our AI-driven solutions are designed to automate processes, enhance decision-making, and deliver personalized experiences. Whether you’re looking to implement machine learning models, natural language processing, or predictive analytics, we help you integrate AI technologies that align with your business goals, driving innovation and efficiency.',
      title9: 'Cloud Services',
      subtitle9:
        'Leverage the scalability and flexibility of cloud computing to optimize your IT infrastructure. Our cloud services range from cloud migration and infrastructure management to cloud-based application development and storage solutions. We help you reduce costs, increase security, and improve operational efficiency by utilizing cutting-edge cloud platforms, ensuring your business is agile and future-ready.',
    },
    HowWeDoIt: {
      title: 'Wie wir Ihnen helfen werden',
      subtitle:
        'Wir sind ein Team von Fachleuten, die sich leidenschaftlich für Technologie und Innovation einsetzen.',
      step1: {
        title: 'Erstellen Sie Ihr Produktkonzept',
        subtitle:
          'Wir arbeiten eng mit Ihnen zusammen, um Ihre Ziele, Ihre Zielgruppe und die Marktlandschaft zu verstehen. Unser erfahrenes Team wird Ihnen dabei helfen, Ihr Produktkonzept zu verfeinern und es in eine gut definierte Vision zu verwandeln, die mit Ihren Geschäftszieleübereinstimmt. Durch strategische Brainstorming-Sitzungen und eingehende Analysen formen wir Ihre Ideen zu einer soliden Grundlage für Ihr Produkt.',
      },
      step2: {
        title: 'Testen Sie Ihre Geschäftsidee',
        subtitle:
          'Bevor wir in die vollständige Entwicklung einsteigen, ist es wichtig, Ihre Geschäftsidee zu validieren. Wir helfen Ihnen dabei, Marktanalysen durchzuführen, Wettbewerber zu analysieren und Benutzer-Tests durchzuführen, um sicherzustellen, dass Ihre Produktidee tragfähig ist und eine starke Markteinführungschance hat. Durch die Entwicklung von Prototypen und Iterationen mit Benutzer-Feedback verfeinern wir Ihr Konzept, um das Erfolgspotenzial zu maximieren.',
      },
      step3: {
        title: 'Bauen Sie Ihr Produkt',
        subtitle:
          'Sobald Ihr Produktkonzept validiert ist, gehen wir in die Entwicklungsphase über. Unser erfahrenes Team aus Entwicklern, Designern und Projektmanagern arbeitet zusammen, um Ihr Produkt mit Präzision und Effizienz zu entwickeln. Durch die Nutzung modernster Technologien und bewährter Praktiken der Branche schaffen wir eine skalierbare, robuste und benutzerzentrierte Lösung, die Ihre Geschäftsziele erfüllt.',
      },
      step4: {
        title: 'Starten Sie Ihr Produkt',
        subtitle:
          'Wir begleiten Sie durch den Produkt-Launch-Prozess und helfen Ihnen dabei, einen strategischen Startplan zu erstellen und effektiv umzusetzen. Von der Marktpositionierung bis hin zu Marketingstrategien bieten wir Ihnen Unterstützung, um Aufsehen zu erregen und den Impact Ihres Produkt-Launches zu maximieren. Unser Ziel ist es, Ihnen zu helfen, stark in den Markt einzutreten und frühen Erfolg zu erzielen.',
      },
      step5: {
        title: 'Reduzieren Sie Ihre Risiken',
        subtitle:
          'Risikomanagement steht im Mittelpunkt unseres Ansatzes. Wir setzen bewährte Methoden und Branchenerfahrung ein, um Risiken während des gesamten Produktentwicklungszyklus zu identifizieren und zu mindern. Durch gründliche Bewertungen, Implementierung von Qualitätssicherungsmaßnahmen und Einhaltung von Industriestandards stellen wir sicher, dass Ihre Produktentwicklungsreise reibungslos und sicher verläuft.',
      },
    },
    contactForm: {
      title: 'Kontaktieren Sie uns',
      title1: 'IHRE DETAILS',
      subtitle: 'Ich möchte Werbe-E-Mails und monatliche Newsletter erhalten',
      button: 'Absenden',
    },
    contactUs: {
      title: 'Kontaktieren Sie uns',
      subtitle:
        'Bereit, den nächsten Schritt zu machen? Wir freuen uns darauf, von Ihnen zu hören. Kontaktieren Sie Hizelus Engineering noch heute, um Ihre Softwarereise zu beginnen und zu erkunden, wie unsere Expertise Ihnen helfen kann, Ihre Ziele zu erreichen. Ob Sie Fragen haben, mehr Informationen benötigen oder Ihre spezifischen Anforderungen besprechen möchten, unser freundliches Team steht Ihnen zur Verfügung. Kontaktieren Sie uns telefonisch, per E-Mail oder indem Sie das untenstehende Kontaktformular ausfüllen, und lassen Sie uns gemeinsam diese transformative Reise beginnen.',
      address: 'Adresse',
      address1: 'Eidelstedter Dorfstr. 3 22527 Hamburg Deutschland',
      phone: 'Telefon',
      phone1: '+4917642056661',
      email: 'E-Mail',
      email1: 'contact@hizelus.com',
      title1: 'Partnerschaft für Ihren Erfolg',
      subtitle1:
        'Nehmen Sie Kontakt mit Hizelus Engineering auf und entdecken Sie die Kraft der kollaborativen Innovation. Unser engagiertes Team ist hier, um Ihre einzigartigen Bedürfnisse zu verstehen, fachkundige Beratung zu bieten und maßgeschneiderte Softwarelösungen zu liefern, die Ihren Erfolg fördern. Ob Sie eine Frage haben, ein Projekt besprechen möchten oder einfach mehr Informationen benötigen, wir freuen uns auf Ihre Nachricht. Kontaktieren Sie uns noch heute und lassen Sie uns gemeinsam eine Reise der digitalen Transformation beginnen.',
      button: 'Absenden',
      title3: 'Sie können uns auch erreichen',
    },
    whatsNext: {
      title: 'Was kommt als Nächstes?',
      step1: {
        title: 'Erstberatung',
        subtitle:
          'Lassen Sie uns mit der Vereinbarung eines ersten Beratungsgesprächs beginnen. Wir werden Ihre spezifischen Bedürfnisse, Ziele und Visionen für Ihr Softwareprojekt besprechen. Dies ist eine Gelegenheit für uns, Ihre Anforderungen zu verstehen und Einblicke zu geben, wie wir Ihnen am besten helfen können.',
      },
      step2: {
        title: 'Projektvorschlag',
        subtitle:
          'Basierend auf den während der Beratung gesammelten Informationen erstellen wir einen detaillierten Projektvorschlag, der den Umfang, den Zeitplan und die Kosten des Projekts umreißt. Dieser Vorschlag dient als Fahrplan für unsere Zusammenarbeit und gibt eine klare Vorstellung davon, was Sie erwarten können.',
      },
      step3: {
        title: 'Anforderungsanalyse',
        subtitle:
          'Nach Genehmigung des Projektvorschlags führen wir gründliche Anforderungserfassungssitzungen durch. Unser Team arbeitet eng mit Ihnen zusammen, um alle funktionalen und technischen Anforderungen Ihrer Softwarelösung zu identifizieren und zu dokumentieren. Dieser Schritt stellt sicher, dass wir Ihre Bedürfnisse umfassend verstehen und eine maßgeschneiderte Lösung liefern können.',
      },
      step4: {
        title: 'Design und Planung',
        subtitle:
          'Mit den Anforderungen erstellen unsere talentierten Designer und Architekten den Plan für Ihre Softwarelösung. Dies umfasst die Erstellung von Wireframes, Prototypen und das Design der Benutzeroberfläche (UI) und Benutzererfahrung (UX). Wir arbeiten eng mit Ihnen zusammen, um das Design zu verfeinern und sicherzustellen, dass es mit Ihrer Marke und Ihren Zielen übereinstimmt.',
      },
      step5: {
        title: 'Entwicklung und Testen',
        subtitle:
          'Sobald das Design abgeschlossen ist, beginnen unsere erfahrenen Entwickler mit der Codierung und dem Aufbau Ihrer Softwarelösung. Wir folgen den besten Praktiken der Branche und agilen Entwicklungsmethoden, um Transparenz, Effizienz und qualitativ hochwertige Ergebnisse zu gewährleisten. Während des gesamten Entwicklungsprozesses werden strenge Tests und Qualitätssicherungsmaßnahmen angewendet, um eine zuverlässige und robuste Lösung zu gewährleisten.',
      },
      step6: {
        title: 'Kunden-Kollaboration',
        subtitle:
          'Wir glauben an die Kraft der Zusammenarbeit, und Ihre Beteiligung ist während der Entwicklungsphase unerlässlich. Wir bieten regelmäßige Updates, suchen Ihr Feedback und nehmen gegebenenfalls Anpassungen vor, um sicherzustellen, dass das Endprodukt Ihren Erwartungen entspricht. Ihr Input und Ihre Erkenntnisse sind von unschätzbarem Wert für die Gestaltung der Lösung.',
      },
      step7: {
        title: 'Lieferung und Support',
        subtitle:
          'Wenn Ihre Softwarelösung fertig und gründlich getestet ist, liefern wir sie Ihnen und sorgen für einen reibungslosen Übergang. Wir bieten umfassende Dokumentation und Schulungen, um Ihnen zu helfen, die Vorteile Ihrer neuen Software zu maximieren. Unser Support endet nicht hier - wir bieten fortlaufende Wartung, Updates und technischen Support, um den langfristigen Erfolg Ihrer Lösung sicherzustellen.',
      },
    },
    aboutUsStatus: {
      customer: 'Erstaunliche Kunden',
      team: 'Teammitglieder',
      products: 'Produkte eingeführt',
      community: 'Community-Mitglieder',
    },
  },
}
