const {
  REACT_APP_EMAILJS_SERVICE_ID,
  REACT_APP_EMAILJS_TEMPLATE_ID,
  REACT_APP_EMAILJS_PUBLICKEY,
} = process.env

const getServiceId = () => {
  return REACT_APP_EMAILJS_SERVICE_ID ?? ''
}
const getTemplateId = () => {
  return REACT_APP_EMAILJS_TEMPLATE_ID ?? ''
}
const getPublicKey = () => {
  return REACT_APP_EMAILJS_PUBLICKEY ?? ''
}

const EnvS = {
  getServiceId,
  getTemplateId,
  getPublicKey,
}

export default EnvS
