import { Col, Container, Row, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./MeetTheTeam.scss";
import weenix from "../../assets/team/weenix.jpeg";
import naren from "../../assets/team/naren.jpeg";
import kenneth from "../../assets/team/kenneth.jpeg";
import vanessa from "../../assets/team/vanessa.jpeg";

import LinkedlnIcon from "../../assets/icons/linkedln-icon";

const MeetTheTeam = () => {
  const { t } = useTranslation(["translation", "de"]);
  return (
    <Container className="meet-the-team">
      <Row className="meet-the-team-title-body">
        <Col>
          <span className="meet-the-team-title">{t('team.title')}</span>
        </Col>
        <Col>
          <span className="meet-the-team-title-subtitle">{t('team.subtitle')}</span>
        </Col>
      </Row>
      <Row>
        <Col className="meet-the-team-content">
          <Card border="light" style={{ width: '18rem' }}>
            <Card.Img className="meet-the-team-image w-100 h-100" variant="top" src={weenix} />
            <Card.Body className="meet-the-team-text-body">
              <div className="meet-the-team-text">
                <Card.Title className="meet-the-team-text-title">{t('team.member1.name')}</Card.Title>
                <Card.Text className="meet-the-team-description">
                  {t('team.member1.position')}
                </Card.Text>
              </div>
              <span className="meet-the-team-icon"><LinkedlnIcon /></span>
            </Card.Body>
          </Card>
        </Col>
        <Col className="meet-the-team-content">
          <Card border="light" style={{ width: '18rem' }}>
            <Card.Img className="meet-the-team-image w-100 h-100" variant="top" src={naren} />
            <Card.Body className="meet-the-team-text-body">
              <div className="meet-the-team-text">
                <Card.Title className="meet-the-team-text-title">{t('team.member2.name')}</Card.Title>
                <Card.Text className="meet-the-team-description">
                  {t('team.member2.position')}
                </Card.Text>
              </div>
              <span className="meet-the-team-icon"><LinkedlnIcon /></span>
            </Card.Body>
          </Card>
        </Col>
        <Col className="meet-the-team-content">
          <Card border="light" style={{ width: '18rem' }}>
            <Card.Img className="meet-the-team-image w-100 h-100" variant="top" src={kenneth} />
            <Card.Body className="meet-the-team-text-body">
              <div className="meet-the-team-text">
                <Card.Title className="meet-the-team-text-title">{t('team.member3.name')}</Card.Title>
                <Card.Text className="meet-the-team-description">
                  {t('team.member3.position')}
                </Card.Text>
              </div>
              <span className="meet-the-team-icon"><LinkedlnIcon /></span>
            </Card.Body>
          </Card>
        </Col>
        <Col className="meet-the-team-content">
          <Card border="light" style={{ width: '18rem' }}>
            <Card.Img className="meet-the-team-image w-100 h-100" variant="top" src={vanessa} />
            <Card.Body className="meet-the-team-text-body">
              <div className="meet-the-team-text">
                <Card.Title className="meet-the-team-text-title">{t('team.member4.name')}</Card.Title>
                <Card.Text className="meet-the-team-description">
                  {t('team.member4.position')}
                </Card.Text>
              </div>
              <span className="meet-the-team-icon"><LinkedlnIcon /></span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MeetTheTeam;